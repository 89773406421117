






import {Chart as highcharts} from 'highcharts-vue';
import Vue from 'vue';

export default Vue.extend({
  components: {highcharts},
  props: {
    options: {type: Object},
  },
  data() {
    return {};
  },
  methods: {
    sort(seriesIndex: any) {
      const serie = this.options.series[seriesIndex];
      const xaxis = this.options.xAxis;

      serie.data = serie.data.sort((a: any, b: any) => a.y > b.y ? 1 : b.y > a.y ? -1 : 0);
      xaxis.categories = serie.data.map((p: any) => xaxis.categories[p.x]);

      this.options.series.forEach((s: any, idx: any) => {
        if (idx !== seriesIndex) {
          s.data = xaxis.categories.map((c: any) => s.data.find((d: any) => d.id === c.id));
        }
      });
    },
  },
});
