

































import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import TableRow from '~/components/common/TableRow.vue';

export default Vue.extend({
  name: 'student-info-table',
  components: {Moment, TableRow},
  props: {
    student: {type: Object, required: true},
    dense: {type: Boolean, default: false},
  },
});
