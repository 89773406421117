





































































































































































import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import TooltipButton from '~/components/common/TooltipButton.vue';
import AssignPreExamDialog from '~/components/student/AssignPreExamDialog.vue';
import AuthorizeAllPreExams from '~/components/student/AuthorizeAllPreExams.vue';
import ChangeTestStateDialog from '~/components/student/ChangeTestStateDialog.vue';
import StudentInfoTable from '~/components/student/StudentInfoTable.vue';
import WeakPointAnalysis from '~/components/student/WeakPointAnalysis.vue';
import {Student, StudentTestState, Test, TestInstance} from '~/models';

export default Vue.extend({
  components: {StudentInfoTable, Moment, WeakPointAnalysis, TooltipButton, AssignPreExamDialog, ChangeTestStateDialog, AuthorizeAllPreExams},
  name: 'StudentDetailsPage',
  data() {
    return {
      assignDialog: false,
      removeDialog: false,
      authorizeAllDialog: false,
      authorize: {dialog: false, studentTestState: null},
      testsWithExtraTry: [],
    };
  },
  async created() {
    await this.$store.dispatch('student/fetchById', this.$route.params.id);
    this.testsWithExtraTry = await this.$store.dispatch('student/getExtraTries', this.$route.params.id);
  },
  computed: {
    student(): Student {
      return Student.query().whereId(this.$route.params.id).withAll().with('testInstances.test').with('testGroups.tests').with('studentTestStates.test').first();
    },
    studentTests(): Test[] {
      const testsFromGroups = this.student.testGroups.reduce((tests, tg) => tests.concat(tg.tests), []);
      const tests = testsFromGroups.concat(this.student.tests);
      return tests.filter((test, index, self) => self.findIndex(t => t.id === test.id) === index); // unique only
    },
    studentTestStates(): any[] {
      return this.student.studentTestStates.filter(sts => !this.testGroups.find(tg => tg.tests.find(t => t.id === sts.testId)));
    },
    testGroupsOverview(): any[] {
      const testTG = this.filterTestGroupsBy('default');
      const progressTG = this.filterTestGroupsBy('progress');
      const finalTG = this.filterTestGroupsBy('final');
      const preExamTG = this.filterTestGroupsBy('preExam');

      return [
        {title: this.$tc('p.test', 2), count: testTG.count, testGroups: testTG.testGroups},
        {title: this.$tc('p.progressTest', 2), count: progressTG.count, testGroups: progressTG.testGroups},
        {title: this.$tc('p.finalTest', 2), count: finalTG.count, testGroups: finalTG.testGroups},
        {title: this.$tc('p.preExam', 2), count: preExamTG.count, testGroups: preExamTG.testGroups, isPreExam: true},
      ].filter(tgo => tgo.count > 0);
    },
    testGroups(): any[] {
      const testGroups = [].concat(this.student.testGroups);
      testGroups.unshift({title: this.$t('label.directlyAssignedTests'), tests: this.student.tests} as any);

      return testGroups
          .filter(group => group.tests.length > 0)
          .map(group => {
            const instances: TestInstance[] = TestInstance.query().where('studentId', this.$route.params.id).withAll().all();
            group.tests = group.tests
                .map(test => {
                  const passed = !!instances.find(instance => instance.testId === test.id && instance.passed);
                  const usedTries = instances.filter(instance => instance.testId === test.id).length;
                  const allowed = !test.dependentOnId || !!instances.find(instance => instance.testId === test.dependentOnId && instance.passed);
                  let status = 'locked';

                  if (passed) {
                    status = 'passed';
                  } else if (usedTries >= test.tries && !this.testsWithExtraTry.includes(test.id)) {
                    status = 'notPassed';
                  } else if (allowed) {
                    status = 'available';
                  }

                  return {...test, passed, usedTries, allowed, status} as any;
                });
            return group;
          })
          .filter(group => group.tests.length > 0);
    },
    breadcrumbs(): any[] {
      const breadcrumbs = [];
      if (this.student.isPresence) {
        breadcrumbs.push({text: this.$t('nav.presenceStudents'), to: {name: 'presence-students'}, exact: true});
      } else if (this.student.isDistance) {
        breadcrumbs.push({text: this.$t('nav.distanceStudents'), to: {name: 'distance-students'}, exact: true});
      } else if (this.student.isStaff) {
        breadcrumbs.push({text: this.$t('nav.staff'), to: {name: 'staff'}, exact: true});
      } else if (this.student.isScriptUser) {
        breadcrumbs.push({text: this.$t('nav.scriptUsers'), to: {name: 'script-users'}, exact: true});
      }
      breadcrumbs.push({text: this.student ? this.student.fullName : this.$route.params.id, disabled: true});
      return breadcrumbs;
    },
    testHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try', 2), value: 'tries'},
        {text: this.$t('label.timeLimit'), value: 'timeLimit'},
        {text: this.$t('label.status'), value: 'status'},
      ];
    },
    preExamHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'test.displayName'},
        {text: this.$tc('p.try', 2), value: 'test.tries'},
        {text: this.$t('label.timeLimit'), value: 'test.timeLimit'},
        {text: this.$t('label.state'), value: 'state'},
        {text: this.$t('label.actions'), value: 'actions', width: '90px', align: 'center', sortable: false},
      ];
    },
    testInstanceHeaders(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try'), value: 'try', align: 'right'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers', align: 'right'},
        {text: this.$t('label.percentage'), value: 'percentage', align: 'right'},
        {text: this.$tc('label.passed'), value: 'passed', align: 'center'},
        {text: this.$t('label.submittedAt'), value: 'finishedAt', align: 'right'},
      ];
    },
  },
  methods: {
    filterTestGroupsBy(type: string) {
      let count = 0;
      const testGroups = this.testGroups.reduce((arr, tg) => {
        const tests = tg.tests.filter(t => t.type === type);
        count += tests.length;
        return arr.concat({...tg, tests});
      }, []).filter(tg => tg.tests.length > 0);
      return {testGroups, count};
    },
    onAuthorizeClick(item: Test) {
      this.authorize.dialog = true;
      this.authorize.studentTestState = item;
    },
    getTestState(test: Test): any {
      return this.student.studentTestStates.find(sts => sts.testId === test.id) || {studentId: this.student.id, testId: test.id};
    },
    getTestStateText(test: Test) {
      const sts = this.getTestState(test);
      return sts.state ? this.$t(`label.${sts.state}`) : this.$t('label.unauthorized');
    },
    onAuthorizeTestClick(test: Test) {
      this.authorize.dialog = true;
      this.authorize.studentTestState = this.getTestState(test);
    },
    async onRemoveAssignmentClick(item: StudentTestState) {
      await this.$store.dispatch('student/removeTestAssignment', {studentId: this.student.id, testId: item.testId});
    },
    getPercentage(item: TestInstance) {
      return Math.floor(item.correctAnswers / item.questionAmount * 100);
    },
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.test.tries) {
        return 'instance-orange';
      }

      const latestTestInstance = this.student.testInstances.filter(ti => ti.testId === item.testId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id) {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
